import validateEN from 'vee-validate/dist/locale/en.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    ascending: 'ascending',
    descending: 'descending',
    description: 'description',
    hello: 'hello',
    hi: 'hi',
    eur: '€',
    kg: 'kg',
    share: 'bond | bonds',
    tree: 'tree | trees',
    dividend: 'interest rate | interest rate',
    myDividend: 'My interest rate | My interest rate',
    myInterestRate: 'My payments',
    prospectus: 'prospectus',
    investment: 'investment | investments',
    interest: 'interest',
    earning: 'Paid-out transactions',
    fund: 'project | projects',
    invest: 'invest',
    investIn: 'invest in this project',
    investMore: 'invest more',
    investNow: 'invest now',
    amount: 'amount',
    total: 'total',
    pay: 'pay',
    payment: 'payment | payments',
    id: 'id',
    serialNumber: 'serial number',
    name: 'name | names',
    firstName: 'First name',
    surname: 'Last name',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    password: 'password | passwords',
    save: 'save',
    tier: 'tier',
    confirm: 'confirm',
    activate: 'activate',
    eIDAS: 'eIDAS',
    gDPR: 'GDPR',
    iDIN: 'iDIN',
    iDeal: 'iDeal',
    and: 'and',
    dashboard: 'dashboard',
    myDashboard: 'My dashboard',
    backToCorekees: 'Back to corekees.com',
    referAFriend: 'Refer a friend',
    back: 'back',
    sold: 'sold',
    participation: 'minimum investment',
    available: 'available',
    download: 'download | downloads',
    brochure: 'brochure',
    identification: 'identification',
    summary: 'summary',
    status: 'status',
    process: 'process | processes',
    processing: 'processing',
    error: 'error',
    success: 'success',
    paid: 'paid',
    fail: 'fail',
    failed: 'failed',
    canceled: 'canceled',
    cancel: 'cancel',
    expired: 'expired',
    open: 'open',
    step: 'step | steps',
    fullName: 'full name',
    telephone: 'telephone',
    companyName: 'company name',
    phone: 'phone',
    dateOfBirth: 'date of birth',
    bankAccount: 'bank account',
    sendDataChangeRequest: 'Send data change request',
    dataChangeRequestPending: 'Data change request is pending',
    dataChangeRequestValue: 'Requested value',
    iban: 'IBAN',
    city: 'city',
    country: 'country',
    address: 'address',
    houseNumber: 'house number',
    number: 'number',
    postalCode: 'postal code',
    welcome: 'welcome Mr./Mrs.',
    availability: 'availability',
    euro: 'euro | euros',
    euroSymbol: '€',
    contact: 'contact',
    countryOfBirth: 'country of birth',
    additionalInfo: 'additional info',
    or: 'or',
    from: 'from',
    to: 'to',
    for: 'for',
    support: 'support',
    subject: 'subject',
    message: 'message',
    login: 'log in',
    register: 'register',
    reset: 'reset',
    resetPass: 'reset password',
    setPass: 'set password',
    secondFactor: '2-factor authentication',
    revertSecondFactor: 'revert 2-factor authentication',
    nationality: 'nationality',
    realEstate: 'real estate index',
    aex: 'aex',
    vs: 'vs',
    property: 'property',
    warning: 'warning',
    document: 'document | documents',
    location: 'location | locations',
    floorplan: 'floorplan',
    benefit: 'benefit | benefits',
    company: 'company | companies',
    setting: 'setting | settings',
    logout: 'logout',
    help: 'help',
    optional: 'optional',
    source: 'source',
    portfolio: 'portfolio',
    fundsLink: 'project | projects',
    knowledge: 'knowledge base',
    glossary: 'glossary',
    referral: 'referral',
    cookie: 'cookie | cookies',
    no: 'no',
    yes: 'yes, this is ok',
    loadMore: 'show more projects',
    loadLess: 'show less projects',
    verified: 'account verified',
    pending: 'account under verification',
    rejected: 'account rejected',
    notVerified: 'account not verified',
    year: 'year | years',
    yearInitials: 'y',
    perYear: 'per year',
    perYearShort: 'p/yr',
    myAccount: 'my account',
    my: 'my',
    details: 'details',
    na: 'n.a.',
    here: 'here',
    send: 'send',
    bond: 'bond | bonds',
    date: 'date',
    orderDate: 'order date',
    endDate: 'end date',
    month: 'month | months',
    day: 'day | days',
    week: 'week | weeks',
    yearly: 'yearly',
    monthly: 'monthly',
    perMonth: 'per month',
    new: 'new',
    search: 'search',
    next: 'next',
    prev: 'prev',
    fundCompleted: 'completed',
    comingSoon: 'coming soon',
    downloadsComingSoon: 'downloads coming soon',
    maxInvestmentsReached: 'maximum invested',
    incorrectPassword: 'incorrect password',
    autoLogOut: 'You have been automatically logged out due to inactivity.',
    investor: 'investor | investors',
    cookiePolicy: 'Cookie policy',
    copyright: 'Bloqhouse 2019',
    accountDisabled: 'Your account has been disabled.',
    contactSupport: 'Please contact {email} or call {phone} for support.',
    supportQuestion: 'How can we help you? Ask your question here:',
    websiteMaintenance: 'Website under maintenance.',
    closeModal: 'Close',
    paidOnline: 'Paid online',
    paidOffline: 'Paid offline',
    dutch: 'Dutch',
    english: 'English',
    gender: 'gender',
    male: 'male',
    female: 'female',
    decline: 'Decline',
    accept: 'Accept',
    disabled: 'This option cannot be changed',
    more: 'more',
    emission: 'emission',
    myCompensation: 'my compensation',
    myCo2Compensation: 'my CO₂ compensation',
    co2Compensation: 'CO₂ compensation',
    compensationProgressBarDetail: 'Annual CO₂ emissions average Dutch person',
    remaining: 'remaining',
    treeNumber: 'Bond number',
    treeAge: 'your oldest bond is {age} old',
    expectedNextPayment: 'expected next pay-out',
    progressOfInvestment: 'progress of investment',
    emissions: 'emissions',
    compensated: 'compensated',
    achievedROI: 'achieved ROI',
    expectedInterestROI: 'expected interest',
    expectedRepaymentROI: 'expected repayment',
    moreDetails: 'more details',
    lessDetails: 'less details',
    duration: 'duration',
    subscription: 'subscription',
    'one-off': 'one off',
    typeOfInvestment: 'investment type',
    'gift-purchase': 'gift purchase',
    'gift-redeem': 'gift received',
    'start subscription': 'start subscription',
    redeemGiftCode: 'redeem giftcode',
    redeem: 'redeem',
    giftCode: 'giftcode',
  },
  validations: {
    ...validateEN.messages,
    alpha_num: 'This field may only contain alpha-numeric characters',
    alpha_spaces: 'This field may only contain alphabetical characters and spaces',
    confirmed: 'This field confirmation does not match',
    email: 'This field must be a valid email',
    integer: 'This field must be a integer number',
    max: 'This field may not be greater than {length} characters',
    min: 'This field must be at least {length} characters',
    ext: 'File type must be of type {types}',
    required: 'This field is required',
    multiplier: {
      payment: 'The amount to participate has to be a multiplier of the price per bond ({number}€).',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      accessibleAndUnderstandable: 'Easy and accessible investments in real estate',
      applyNowInvestor: 'Register now and become a real estate investor!',
      exploreProperties: 'Explore real estate',
      registerNow: 'Register now',
      explorePropertiesBtn: 'Explore projects',
    },
    accessible: {
      accessibleAndTransparent: 'Accessible and transparent',
      weMakeItVeryEasy: 'It is Bloqhouse\'s mission to make investing in real estate transparent and accessible',
      hasManyYears: 'Shared ownership in real estate makes it possible to invest without having to put time and effort into management',
      youCanStartBuying: 'After choosing one of the objects on the platform you can invest within minutes',
      onceTheSaleIsCompleted: `Your identity will be verified via iDIN and bonds are for sale staring from € 100 to € 100,000 via iDEAL
        (shares above € 100,000 are via SEPA bank transfer)`,
      startBuying: 'Buy real estate bonds starting from € 100',
      receivedMonth: 'Receive interest rate per quarter in Euros',
      tradeYourShares: 'Sell your bonds via the trading platform (launch: end of 2019)',
    },
    fund: {
      emissionPrice: 'Price per Bond',
      totalFundSize: 'Total project size',
      seeDetails: 'See details',
    },
    counters: {
      emissionCosts: 'Emission costs',
      grossDividendYield: 'Gross interest rate yield',
      netDividendYield: 'Interest rate yield',
      dividendsPerYear: 'interest rate per year',
      occupancyRate: 'Occupancy rate',
    },
    benefits: {
      exploreProperties: 'Explore properties',
      tab1: 'Invest directly',
      tab1Title: 'Invest directly',
      tab1Text: `Choose an object and decide how much you want to invest. With an average of one extra project per two months,
      Bloqhouse strives to facilitate the largest real estate offer in the Benelux. You can put together your own portfolio
      that meets your criteria. In addition, it is easy to diversify with different types of real estate. There is a
      one-time cost of 2% connected to an investment`,
      tab2: 'Receive interest rate ',
      tab2Title: 'Receive interest rate ',
      tab2Text: `You receive interest rate on your investment every quarter.
        The interest rate consists of the rental income minus the management costs of the property`,
      tab3: 'Check your return',
      tab3Title: 'Check your return',
      tab3Text: `Check the performance of your investments on your dashboard. All of our properties are appraised every
        6 months by an RICS certified appraiser. This allows you to estimate the value fluctuation of the purchased real estate.
        The interest rate income is automatically included in the dashboard`,
      tab4: 'Liquidity',
      tab4Title: 'Liquidity',
      tab4Text1: `It’s possible to offer bonds at any time via our trading platform. Selling is simple. Place your bonds
        up for sale on the trading platform. Or alternatively: wait for the 5-yearly sale of the real estate,
        whereby investors will receive the potential surplus value of the property. The average time to sell a bond is 4 days`,
      tab4Text2: `Depending on the chosen price, it may take longer or shorter. Please note: during the introduction period of
        the trading platform, the trading volume will be lower than average, which may result in a longer sale time`,
      tab5: 'Overhead',
      tab5Title: 'Overhead',
      tab5Text: `Investing in real estate is time-consuming and complex. Bloqhouse changes that. Paperwork, maintenance,
        lawyers and defaulters are now a thing of the past. Bloqhouse and the affiliated real estate projects arrange that for you,
        with many decades of market experience. This way you can profit from rental income and any potential value increases in real
        estate, without any hassle!`,
      tab5TextPoint1: 'Search for investments',
      tab5TextPoint2: 'Negotiation process',
      tab5TextPoint3: 'Paperwork and lawyers',
      tab5TextPoint4: 'Search for tenants',
      tab5TextPoint5: 'Maintenance and repairs',
      tab5TextPoint6: 'Valuations',
      tab5TextPoint7: 'Sale process',
      tab6: 'Our policy',
      tab6Title: 'Our policy',
      tab6Satisfied: 'Satisfied tenants:',
      tab6Text1: `Bloqhouse believes that satisfied tenants are the key to future success. Our real estate projects demand fair
        rents and maintain the property thoroughly, so that tenants stay for longer periods of time. This means stable rental
        income for you`,
      tab6SmartPurchases: 'Smart purchases:',
      tab6Text2: `Bloqhouse works together with large real estate projects in the Netherlands. As a result, we find
        the best purchases, which are usually and often owned by large companies or wealthy individuals`,
    },
    investing: {
      investingMadeSimple: 'Investing made simple',
      selectTheFund: 'Select the project in which you want to invest',
      registerAndIdentify: 'Register and identify via iDIN',
      buyYourShares: 'Buy your Bonds via iDeal',
      receiveMonth: 'Receive interest rate per quarter',
    },
    stock: {
      amsterdamStockExchange: 'Amsterdam Exchange Index',
      pastPerformance: 'Past performance is no guarantee for future results',
    },
    safeGuards: {
      investmentSafeguards: 'Investment safeguards',
      weTakeOurResponsabilities: 'We have taken responsibility to provide you with trust and ease-of-mind while investing',
      yourInvestmentsAreRingFenced: 'Your investments are protected',
      andAreSeparated: 'They are protected and separated from the assets and liabilities of Bloqhouse',
      compensationScheme: 'Compensation Scheme',
      protectsTheFunds: 'Your bank balance on the platform is insured via a Third-party projects foundation',
      financialConductAuthority: 'Dutch Financial Markets Authority',
      isTheBody: 'The platform is regulated by the Dutch Financial Markets Authority',
    },
    expectations: {
      assetsUnderManagement: 'Assets under management',
      capitalReturnedToInvestors: 'Capital returned to investors',
      rentalIncome: 'Rental income returned to investors',
    },
    faq: {
      howDoIIdentify: 'How do I identify myself on the platform?',
      forDigital: 'To digitally identify our private investors, we use iDIN, an application developed by the Dutch banks',
      howIGetDividends: 'How will I receive my interest rate?',
      theDvidendsare: 'The interest rate on the Bloqhouse platform is paid in the form of Euros deposited into your account',
      howCanISell: 'How can I sell my investment?',
      youCanIn2Ways: `This can be done in two ways. (1) Through the trading platform you can place your investment up for sale at any time. (2)
        After the term of the project, the value of the property is divided pro rata among the bondholders at that time`,
      whenIsItSold: 'When is the real estate project sold / project liquidated?',
      theAverage: 'The average duration of a project is 5 years',
      isMyInvestmentInsured: 'Is my investment insured against accidents?',
      yesAllFundsAre: 'Yes, all the projects of our affiliated companies are fully insured, so that you, as an investor, are not at risk',
      howDoIPay: 'How do I pay for my bonds?',
      sharesCanBe: 'Bonds can be purchased via iDEAL. Bitcoin and PayPal will be possible soon',
      whatHappens: 'What will happen to my investment if the project is not filled?',
      inThatCase: 'In that case, your investment will immediately be refunded to your bank account',
      upUntillWhichAmount: 'Up to how many Euros can I pay directly online via iDEAL?',
      till100k: 'You can pay directly online up to € 100,000, otherwise a bank transfer will have to be made via SEPA',
      whoDecides: 'Who makes the decisions regarding the project and real estate management?',
      thatIsBeingCared: 'The project managers do this',
      questionsAnswers: 'Questions & Answers',
    },
    risks: {
      investmentRisks: 'Please note',
      generalRisksAssociated: `There are general risks associated with investing in property projects: the market risk, risk with regard to licensing
      requirements, risk of stock interest rate, return risk, financing and interest rate risk, rental and vacancy risk, tax risk, inflation risk and
        risks associated with the manager`,
      theMainRisks: 'Bloqhouse is not obliged to purchase bonds',
      aDetailedDescription: 'A detailed description of the risks can be found in the prospectus of the related project',
    },
    cookies: {
      allowUs: 'Do you allow us to store {cookiesLink}?',
      yourInfoAnalytics: 'Your information will be used for analytics and website optimisation',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Forgot password?',
      dontHaveAnAccountYet: 'Don\'t have an account yet?',
      accessYourBloqify: 'Access your Bloqify',
      enterYourPassword: 'Enter your password',
      pleaseActivate: 'Please activate your account using the activation link sent to your e-mail',
      userHasRole: 'You tried to log in with an admin account. Please use it in Bloqadmin only.',
      captchaTimeExpired: 'Captcha verification expired',
      tryAgain: 'Please try again',
      error: 'Something went wrong!',
      descriptionTitle: 'In jouw dashboard kun je',
      descriptionPoint1: 'Invest in our sustainable projects.',
      descriptionPoint2: 'Gain insight into your expected returns and payments.',
      descriptionPoint3: 'Invest safely; your personal data is always well protected by us.',
    },
    register: {
      becomeAnInvestor: 'Become an investor',
      atLeast10Chars: 'At least 10 characters',
      alreadyHaveAnAccount: 'Already have an account?',
      thankYou: 'Thank you for registering',
      pleaseCheckEmail: 'Please check your e-mail to activate your account',
      itCanTake: 'It can take a few minutes to arrive',
      youCan: 'You can',
      afterActivating: 'after activating your account',
      confirmPassword: 'Confirm password',
    },
    reset: {
      getAPassword: 'Get a password reset link',
      sendResetLink: 'Send reset link',
      rememberYourPassword: 'Remember your password?',
      emailSent: 'If the provided email is registered, you should have received an email',
      pleaseCheckInbox: 'Please check your inbox',
      expiredError: 'For security reasons, this link was valid only for 24 hours. Please, insert your e-mail so we can send you an email with a new link to claim your password.',
      reactivate: 'Reactivate your Corekees account',
    },
    verification: {
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      emailVerification: 'E-mail verification',
      resetPassword: 'Reset password',
      claimPasswoord: 'Claim password',
      changeEmail: 'Change e-mail',
      verifyingSuccess: 'Everything is set, you can log in now',
      verifyingError: 'Something went wrong! Register again',
      changeError: 'Something went wrong! Try again',
      changeSuccess: 'Everything is set, you can log in',
      passwordLenght: 'Password has to be 10 or longer',
      changeDataRequestConfirmation: 'Are you sure you want to change your <strong>{type}</strong> from <em>{oldValue}</em> to <em>{newValue}<em/>',
      revertSecondFactor: 'You activated second factor authentication, on this page you can revert this action',
      revertSecondFactorSuccess: 'You successfully deactivated 2-factor authentication',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verify',
      dismiss: 'Hide message',
      notNow: 'Not now',
      pending: 'Your account is being verified',
      pendingDescription: 'You will be notified soon by {fundManager}',
      youCanInvest: 'You can already invest in your first project',
      youNeedApproval: 'You can only invest after verifying your account',
      upgradeTo: 'Complete your profile',
      basicUser: 'We still need some information from you',
      payments: {
        openPayment: 'The Payment for {shareCount} bonds of {assetName} is still pending.',
        sepaPayment: 'A SEPA payment can take some days to be processed, if you haven\'t done so yet initiate your payment now.',
        makePayment: 'Make Payment',
        openPayments: 'There are {pendingPayments} open payments.',
        sepaPayments: 'SEPA payments can take some days to be processed, if you haven\'t done one yet initiate it now.',
        makePayments: 'See open payments',
        pendingPayments: 'Pending Payments',
      },
    },
    balance: {
      totalBalance: 'My investments',
      onTheseFunds: 'In these projects',
      annualStatement: 'Tax statement',
    },
    dividends: {
      onTheseFunds: 'From these projects',
      comingSoon: 'The percentage of interest rate received will be shown here soon',
    },
    funds: {
      onBoard: 'In portfolio',
      notOnBoard: 'Not in portfolio',
      totalValue: 'Fund size',
      myInvestment: 'My investment',
      totalEarnings: 'in total',
      netDividendYield: 'Interest rate',
      netDividendYieldMine: 'My payments',
      startToInvest: 'Start to invest on your first project',
      investInOtherFunds: 'Invest on other projects to earn more interest rate',
      investMoreOnSingleFund: 'Explore other properties',
      showAlltheFunds: 'Show all projects',
      myPortfolio: 'My Dashboard',
      myFunds: 'Investments',
      otherFunds: 'Projects',
      myFundsPlaceholder: 'All the projects you have invested in will be listed here',
      myFundsStartInvest: 'Invest on your first project',
      progress: 'Project progress',
      more: 'more',
      premium: 'This is a Premium project, please log in to see it',
      premiumLabel: 'Premium project',
      premiumFund: 'Premium project',
    },
    investments: {
      showPayments: 'Show transactions',
      hidePayments: 'Hide transactions',
      loadAllInvestments: 'Load all investments',
      noPayments: 'There are no investments to show yet',
      requestSupport: 'Request support',
      showFewerPayments: 'Show fewer payments',
      showAllPayments: 'Show all payments',
      active: 'active',
      ended: 'ended',
      started: 'started',
    },
    support: {
      technical: 'Technical',
      technicalQuestion: 'Technical question',
      fundSpecificQuestion: 'Fund specific question',
      aboutTheFund: 'About the project',
      sendMessage: 'Send',
      messageSent: 'Your message has been sent',
      youWillBeAnswered: 'We will answer you by email as soon as possible',
    },
    errors: {
      pdfError: 'There was an error with retrieving the document. Please try again later or contact support',
      noInvestmentsError: 'There are no investments for the selected year',
      giftCodeIncorrect: 'This gift code is incorrect.',
      giftCodeAlreadyRedeemed: 'This gift code is already redeemed.',
      giftCodeExpired: 'This gift code is expired.',
    },
    table: {
      count: 'Showing {from} to {to} of {count} records|{count} records|One record',
      first: 'First',
      last: 'Last',
      filter: 'Filter:',
      filterPlaceholder: 'Search query',
      limit: 'Records:',
      page: 'Page:',
      noResults: 'No returns have been paid out yet.',
      filterBy: 'Filter by {column}',
      loading: 'Loading...',
      defaultOption: 'Select {column}',
      columns: 'Columns',
      progress: {
        noResults: 'You didn\'t make an investment yet.',
      },
      payments: {
        noResults: 'You didn\'t make any payments yet.',
      },
      bonds: {
        noResults: 'You don’t have any bonds yet.',
      },
      earnings: {
        noResults: 'You did not receive any payments.',
      },
    },
  },
  settings: {
    details: {
      firstAndLastName: 'First and last name',
      changePassword: 'Change password',
      changeEmail: 'Change e-mail',
      newPasswordOptional: 'New password',
      newPasswordOptionalRepeat: 'Repeat new password',
      confirmWithCurrentPassword: 'Confirm with current password',
      personalDetails: 'Personal details',
      leaveEmpty: 'Leave empty if you do not want to change your password',
      useAtLeastTen: 'Use at least 10 characters to keep your account secure',
      pleaseConfirm: 'In order to change password, please insert the old password first',
      currentPassword: 'Current password',
      newEmail: 'New email',
      passwordToChangeMail: 'Type your current password to change your E-mail address',
      changeHere: 'This is the current IBAN number known to us, change if necessary and click on save',
      mailChanged: 'Your e-mail address has been changed. Please check your e-mail to activate your new e-mail address and login again',
      bankAccountChanged: 'Your bank account was correctly changed',
      passwordChanged: 'Your password has been changed. Please log in again',
      nameUpdated: 'Your name has been updated',
      linkSent: 'Please, open the link we just sent to your e-mail in order to confirm the change on your bank account number',
      identity: 'Personal Information',
      credentials: 'Credentials',
    },
    identification: {
      identification: 'Identification',
      complete: 'Verify account',
      support: 'Support',
      title: 'Complete these steps to gain full access',
      userIdentification: 'Create an account',
      registeredToPlatform: 'You have created an account',
      investorIdentification: 'Verify your account',
      completeStep: 'Complete this step and start investing',
      success: 'Your account has been identified',
      rejected: 'Your identification request was rejected',
    },
    tier: {
      currentTier: 'Not applicable',
      cannotRequestTier: 'You cannot request a tier upgrade at the moment',
      workingOnFunctionality: 'We are still working on this functionality',
    },
  },
  help: {
    baseKnowledge: {
      baseKnowledge: 'Base knowledge',
      rics: 'RICS',
      aex: 'AEX',
      afm: 'AFM',
    },
    faq: {
      faq: 'FAQs',
      isIdinSecure: 'Is iDIN secure?',
      howPersonalInformation: 'How is my personal information handled?',
      howInvestDutch: 'How to invest without a Dutch bank account?',
      howInvestCompany: 'How can I invest on behalf of a company?',
      iDINAdheres: 'iDIN adheres to European legislation, i.e.',
      contactOnUpdates: 'We will only contact you for important updates about your account and your investments',
      pleaseSend: 'Please send us a',
      manualRequest: 'manual investment request',
      howDoIIdentify: 'How do I identify myself on the platform?',
      forDigital: 'To digitally identify our private investors, we use iDIN, an application developed by the Dutch banks',
      howIGetDividends: 'How will I receive my interest rate?',
      theDvidendsare: 'The interest rate on the Bloqhouse platform is paid in the form of Euros deposited into your account',
      howCanISell: 'How can I sell my investment?',
      youCanIn2Ways: `This can be done in two ways. (1) Through the trading platform you can place your investment up for sale at any time. (2)
        After the term of the project, the value of the property is divided pro rata among the bondholders at that time`,
      whenIsItSold: 'When is the real estate project sold / project liquidated?',
      theAverage: 'The average duration of a project is 5 years',
      isMyInvestmentInsured: 'Is my investment insured against accidents?',
      yesAllFundsAre: 'Yes, all the projects of our affiliated companies are fully insured, so that you, as an investor, are not at risk',
      howDoIPay: 'How do I pay for my bonds?',
      sharesCanBe: 'Bonds can be purchased via iDEAL. Bitcoin and PayPal will be possible soon',
      whatHappens: 'What will happen to my investment if the project is not filled?',
      inThatCase: 'In that case, your investment will immediately be refunded to your bank account',
      upUntillWhichAmount: 'Up to how many Euros can I pay directly online via iDEAL?',
      till100k: 'You can pay directly online up to € 100,000, otherwise a bank transfer will have to be made via SEPA',
      whoDecides: 'Who makes the decisions regarding the project and real estate management?',
      thatIsBeingCared: 'The project managers do this',
    },
  },
  checkout: {
    fund: {
      selectAnAmount: 'your investment',
      moreInfo: 'more info',
      emissionPricePerShare: 'Price per Bond',
      totalFundSize: 'Total project size',
      emissionCosts: 'Emission costs',
      grossDividendYield: 'Gross interest rate yield',
      netDividendYield: 'Interest rate yield',
      verifyYourIdentity: 'verify your identity',
      fundDocs: 'project docs',
      noFilesFound: 'no files found',
    },
    investment: {
      selectTheAmountTrees: 'Select the number of trees',
      selectTheAmountBonds: 'Select the number of bonds',
      selectPaymentOption: 'Investment type',
      selectPaymentMethod: 'Select payment method',
      referralCode: 'Promotion-/referralcode',
      couponCode: 'Referral Code',
      referralCodeSuccess: 'You successfully entered a valid referral code',
      couponCodeSuccess: 'You successfully entered a valid code',
      codeInvalid: 'Code is invalid. Please enter a valid code',
      singlePayment: 'one off investment',
      giftPurchase: 'trees as a gift',
      recurringPayment: 'subscription',
      ideal: 'iDEAL',
      sepa: 'SEPA',
      bancontact: 'BanContact',
      sofort: 'SOFORT',
      amountIn: 'Amount in',
      amountPlaceholder: 'Number',
      investmentCosts: 'Investment',
      emissionCosts: 'Emission costs',
      discount: 'discount',
      totalCosts: 'Total investment',
      maximumInvestment: 'Maximum investment with your current account',
      placeOrder: 'place order',
      startThePayment: 'start the payment',
      surpassedMax: `The amount you are trying to buy, added up to what you previously invested in this asset,
        would exceed the limit (€ 100.000).`,
      amountBelowZero: 'Oops. The amount is not a number or smaller than 0.',
      selectAnOption: 'select an option',
      dividends: 'select interest rate yield',
      availableFund: 'The progress bar shows the availability of the project',
      legalTerms: 'Confirm',
      finalize: 'And finalise the payment',
      questionnaire: 'And move to next step',
      giftPurchaseNote: `Please note: the number of trees selected is the value of your tree-gift-card for one receiver.
        You will have to purchase separate tree-gift-cards if you want to give more people a Corekees tree.`,
    },
    questionnaire: {
      questionnaire: 'Questionnaire',
      mandatory: 'All fields are mandatory',
      errorLoadingQuestions: 'Error loading questions',
    },
    legal: {
      accept: 'Accept terms and conditions',
      continue: 'And pay with Pay.nl',
      legalAgreement: 'Terms and conditions',
      paymentRecap: 'Your investment',
      finalisingPayment: 'Almost there...',
      interestRate: 'Interest rate',
      byThisField: 'By checking this box, I agree with and understand that:',
      gtac: 'General Terms and Conditions',
      bondConditions: 'Bond Conditions',
      avgPolicy: 'AVG Policy',
      point1: 'I am investing in a variable rate bond. I understand that my interest rate may be lower than expected, and that I may lose my initial investment.',
      point2: 'I agree to the {0}.',
      point3: 'I agree to the {0}.',
      point4: 'I understand that Corekees Foundation collects and stores my data in accordance with their {0}.',
      point5: 'I hereby authorise the Corekees Foundation to automatically charge the subscription fee from my bank account with SEPA Direct Debit until cancellation of my subscription.',
      point6: 'I am at least 18 years of age and not a citizen of the United States.',
    },
    manualOrder: {
      requestForInvestment: 'Request for investment',
      amountToInvestIn: 'Amount to invest in',
      sendRequest: 'Send request',
      youCanAlsoContact: 'You can also contact us via e-mail or phone',
      weWillGetYouBack: 'We\'ll get back to you within a few working days',
    },
    status: {
      paymentCompleted: 'Payment completed',
      paymentCanceled: 'Payment failed',
      paymentFailed: 'Payment failed',
      paymentPending: 'Payment pending',
      paymentNotFound: 'Payment not found',
      paymentProcessing: 'Payment is being processed',
      youBought: 'You bought <strong>{shares} bond</strong> for <strong>{euro} €</strong> | You bought <strong>{shares} bonds</strong> for <strong>{euro} €</strong>',
      youTriedToBuy: 'You tried to buy <strong>{shares} bonds</strong> for <strong>{euro} €</strong>',
      paymentCanceledDescription: 'The payment did not succeed. Please try again and contact us if this problem persists.',
      paymentPendingDescription: 'We are processing your payment, you will receive an update when the payment is completed.',
      tryAgain: 'try again',
      accountInvestAgain: 'Check other opportunities',
      backToThePortfolio: 'Go to Dashboard',
      congratulations: 'Congratulations',
      open: {
        makePayment: 'Make Payment',
        banktransfer: {
          header: 'We are awaiting your bank transfer',
          info: 'A SEPA transaction can take some days to be processed, if it is already initiated please be patient',
        },
        other: {
          header: 'We are awaiting your payment',
          info: 'Your payment is still open, please make it now',
        },
      },
    },
    unlimited: {
      investMoreThan: 'Invest More Than',
      ourProvider: 'Our payment provider (Pay.nl) currently can not support payments above',
      toInvestMore: 'To invest more than this amount, please send a manual investment request or make multiple payments',
      startRequest: 'Start request for investment',
      youCanEnter: 'You can enter your details and your investment amount here',
      managerContacts: 'Manager contacts',
    },
    errors: {
      notEnoughPermissions: 'Not enough permissions with this credentials',
      somethingWentWrong: 'Your verification has not been completed.',
      somethingIsMissing: 'Something is missing',
      somethingIsNotWorking: 'Something is not working',
      problemPersist: 'Contact us if the problem persists',
      error404: 'Error 404 - Page not found',
      backToHomepage: 'Back to homepage',
    },
    redirect: {
      text: 'Generating payment via Pay.nl, our payment service provider...',
    },
    corekees: {
      trees: {
        title: 'Invest in project Pongamia',
        eenmaligInvesteren: 'One off investment',
        eenmaligInvesterenText: 'Plant trees that produce green fuel and actively absorb CO₂. Expect an average return of 7.8% per tree, per year while doing so.',
        maandelijksInvesteren: 'Tree-Subscription; invest on a monthly basis',
        maandelijksInvesterenText: `Plant trees on a monthly basis. After your initial payment, your subscription will be charged
        automatically (SEPA Direct Debit). A subscription has several advantages:`,
        maandelijksInvesterenListOne: 'Volume discount – More trees per month = cheaper trees = a higher return. Up to an average expected yearly returns from 8.5% (up from 10 trees per month).',
        maandelijksInvesterenListTwo: 'Risk spreading - By spreading your tree planting, you reduce the agricultural risk. Trees are batched and harvested by age.',
        maandelijksInvesterenListThree: 'Saving trees start at €22.50 a month (= 1 tree/month)',
        giftPurchase: 'Trees as a gift',
        giftPurchaseText: `With a tree-gift-card, you can give Corekees’ trees as a gift. Please note: tree-gift-cards are one-off (so no subscriptions) and the number of
        trees selected is the value of your tree-gift-card for one receiver. Would you like to give more people a Corekees tree? You will have to purchase separate tree-gift-cards.
        You can do this by clicking ‘Invest’ in your dashboard and walk through the check-out separately for every tree-gift-card.`,
      },
      gras: {
        title: 'Invest in project Elephant Grass',
        par1title: 'A limited crowdfunding campaign',
        // eslint-disable-next-line
        par1text: 'Project Elephant Grass is a limited campaign aiming to plant 30 acres of Elephant Grass in Kenya. One Bond costs €65 and is equivalent to 50 m2 of Elephant Grass. Due to the nature of this project, there are only 2391 Bonds available, and we expect them to sell out fast. In addition to returns, the bonds provide an estimated carbon offset of 55 kg per bond per year! Moreover, this form of investment has several other advantages:',
        listItem1: 'Low threshold - You can invest from as little as €65.',
        listItem2: 'Short term - The bond is fully redeemed after five years',
        listItem3: 'Geographical spread - A great addition to your bonds in Project Pongamia',
        listItem4: 'Attractive Returns - Expected average annual returns of 8.5%',
        par2title: 'Elephant Grass',
        // eslint-disable-next-line
        par2text: 'Elephant grass grows super fast, becomes very tall, and can be harvested several times a year. The harvested grass will be processed into biofuel briquettes that we sell to local manufacturers. This way, we create a sustainable alternative to firewood or fossil fuel use, prevent deforestation and contribute to accelerating the Energy transition.',
      },
      bamboo: {
        title: 'Invest in project Bamboo',
        par1title: 'A one-off investment campaign',
        // eslint-disable-next-line
        par1text: 'With this project, you invest in European bamboo forests. Bamboo grows 15 times faster than wood, absorbs large amounts of CO₂ and is a biodegradable product with over 10,000 different uses. We sell the harvested bamboo to European companies that incorporate it into numerous products. In this way, we contribute to developing a new bio-based industry in Europe and ensure the greening of our continent.',
        listItem1: 'A European project - Cooperation with a Dutch company in Portugal',
        listItem2: 'Attractive Returns- An expected return of 6.7% per year on average',
        listItem3: 'Certified CO₂ disposal - ONCRA has issued carbon credits for this projects',
        listItem4: 'Geographical spread - An excellent addition to your investments in Pongamia or Elephant Grass',
      },
    },
  },
  identification: {
    privateInvestor: 'Private investor',
    selectAnOption: 'Select an option',
    dutchBankAccount: 'Invest as private investor with Dutch bank account',
    noDutchBankAccount: 'Invest as private investor with non-Dutch bank account',
    businessTitle: 'Business investor',
    businessInvestment: 'Invest with your business',
    questionnaire: {
      intro: 'Before you verify your identity please answer these questions:',
      submit: 'Submit Answers',
      move: 'And move to identification',
    },
    business: {
      fillFieldsCompany: 'Fill the fields with your company info',
      kvkNumber: 'KVK number',
      uploadYourKvKImage: 'Upload your KvK statement',
      submitYourData: 'Submit your data',
      orContactUs: 'Contact us',
      youCanAlso: 'You can also contact us via e-mail or phone',
      weWillGetBackToYou: 'We\'ll get back to you within a few working days',
    },
    idin: {
      selectAnAmount: 'select an amount',
      identityVerification: 'Identity verification',
      pleaseVerify: 'Please verify your identity using',
      verificationService: 'a verification service provided by Dutch banks',
      completedIdin: 'We\'ve completed your iDIN verification',
      pleaseWait: 'Please wait while we connect to iDIN',
      pleaseSelectAnOption: 'Please, select an option',
      selectBank: 'Select your bank',
      thereWasAnError: 'There was an error with iDIN',
      pleaseContactSupport: 'Please contact support',
      whatIsIdin: 'iDIN explained',
      idinExplanation: `iDIN is a Dutch digital identity provider. Individuals can use iDIN to identify with a company such as {companyName}.
        A person logs in at his or her bank. The bank then passes on the data from the person, who has already been checked by the bank, to {companyName}.
        All customers of the bank that have been fully identified by the bank can use iDIN.`,
      idinError: 'There was an error with iDIN service, please try again or contact support',
    },
    world: {
      fillWithInfo: 'Fill the fields with your info',
      uploadYourPassport: 'Upload your identification document (passport, ID, residence permit, drivers license)',
      submitYourData: 'Submit your data',
    },
    FormStatus: {
      requestSent: 'Request sent!',
      weAreCheckingYourData: 'We are verifying your account. In the meantime you can already invest.',
      youAreVerified: 'You are verified and able to invest!',
      exploreProperties: 'Explore properties',
      requestRejected: 'Request rejected!',
      weRejectedYourRequest: 'Because your account doesn\'t meet our verification terms, you can\'t do investments. Please contact us for more information.',
      redirect: 'Go back to dashboard',
      proceedInvestment: 'Proceed with investment',
    },
  },
  properties: {
    exploreProperty: 'More details',
    startDate: 'Started in',
    noResults: 'We could not find any property with this name, please try again',
  },
  property: {
    propertyInfo: {
      investMore: 'Invest more',
      investOnThisFund: 'Invest in this project',
    },
    propertyContent: {
      progress: 'Progress of the project',
      investmentCase: 'Investment case',
      propertyDetails: 'Property details',
      additionalInfo: 'Additional info',
    },
    reservation: {
      title: 'Subscribe for updates',
      confirm: 'Please fill in the mandatory fields to show your interest in <strong>{name}</strong>.',
      error: 'Something went wrong.',
      success: 'Reservation successfully submitted!',
    },
  },
  giftCode: {
    title: 'Redeem your gift code',
    description: 'Please fill in your received giftcode below to add the gifted tree/trees to your account.',
    descriptionNoInvestor: 'You first need to complete your registration before you can redeem a gift code.',
    agreement: {
      description: 'By checking this box:',
      point1: 'I agree to the <a href="https://corekees.com/wp-content/uploads/2021/05/20210605-Algemene_Voorwaarden_Stichting_Corekees.pdf" target="_blank">General Terms and Conditions</a> and the <a href="https://corekees.com/wp-content/uploads/2021/05/20210506-Obligatievoorwaarden_Stichting_Corekees.pdf" target="_blank">Bond Conditions</a>.',
      point2: 'I agree that the Corekees Foundation collects and stores my data in accordance with their <a href="https://corekees.com/wp-content/uploads/2020/09/The_Corekees_Foundation_GDPR_Policy.pdf" target="_blank">GDPR Policy</a>.',
      point3: 'I declare that I am at least 18 years of age and not a citizen of the United States.',
    },
    redeem: {
      success: 'Gift code successfully redeemed!',
    },
  },
  referralCode: {
    title: 'Share your referral code',
    description: `When someone uses your unique code when doing an investment, they will receive a €50 discount.
                  You will receive a €50 discount code in your mail that you can use on your next investment.
                  This promotion is valid indefinitely: the more friends you introduce, the more discount codes you will receive.`,
    friend: 'Friend',
    friends: 'Friends',
    credit: 'Credit',
    you: 'You',
    friendBenefit: 'Invest with a €50 discount',
    youBenefit: 'Get €50 off your<br>next order',
    referredFriends: 'Number of friends who are<br>saving through you.',
    referredCredit: 'Total credit that other friends have saved through you.',
    copySuccess: 'Referral code successfully copied to clipboard',
    termsAndConditions: 'Read the terms and conditions',
  },
  tooltip: {
    amountOwned: 'This is the total number of the Bonds you hold',
    amountGet: 'Total realised (repayment + return) payments in euro’s of your bonds',
    amountCompensation: 'The total amount of CO₂ you compensated yearly in kilograms',
    fundSize: 'Total value of the project',
    availability: 'Available amount of bonds',
    netDividendYieldMine: 'Euros you will receive per year',
    netDividendYield: 'The percentage you will receive per year',
    yourInvestment: 'The total amount you invested in this project',
    durationMsg: 'Duration of the project',
    interestMsg: 'Expected return per year',
    compensationMsg: 'Number of kg CO2 compensation per year',
    yourInterestRate: 'Total interest you received',
    emissionCosts: 'The fee applied to purchase a bond',
    fundProgress: 'Percentage of the project that has been sold',
    discount: 'Promotion code and/or volume discount',
    /* eslint-disable max-len */
    promotionCode: 'Enter your promotion code or referral code here. A promotion code or referral code is only valid for one-off investments. You can submit a gift code to the relevant project in the project overview.',
    onlinePayment: 'This payment was made through the platform',
    offlinePayment: 'This payment was made outside of the platform',
    downloadStatement: 'Select the year on the left and click here to download the tax statement you need',
    initialLogin: 'Click here to see your dashboard and investment overview',
    remainingDesc: 'Remaining trees until climate neutral',
    emissionDesc: 'Annually compensated CO₂ emissions',
  },
  gdpr: {
    bar: {
      title: 'Control the collection and use of your personal data',
      textMain: `With your consent, our partners and we use cookies or equivalents to collect, store and use some of
      your personal data, such as your visit on this website. They are used to analyse audiences, offer personalised content
      and / or targeted advertising campaigns, improve customer knowledge, optimize services and fight fraud.
      Click on the “Accept all” button to consent to these uses or click on “Set your options” to obtain more details
      and / or reject all or part of them.`,
      textWarning: `Warning: By continuing your browsing on this site, you accept these uses. You can change your
      mind at any time.`,
      setOptions: 'Set your options',
      acceptEverything: 'Accept everything',
    },
    modal: {
      headerTitle: 'Our Data Collection Policy',
      headerText: '{companyName} makes use of \'cookies\' on this page. A cookie is a little piece of information stored on your device. By that we are able to recognise you at your next visit.',
      headerTitle2: 'Why we use cookies',
      /* eslint-disable max-len */
      headerText2: 'We use cookies in order to ensure the functionality and security of our website. Further, this allows us to analyse and improve our site, products, and services. By these cookies we recognise you at the next visit. No phone numbers or e-mail addresses are saved in them. You allow us to use these cookies by saving your preferences by clicking \'save\'. \n\n We use different types of cookies\nIn particular four types of cookies: Functional Cookies, Analytics Cookies, Advertisment, and Personalisation cookies. Find below more details and the selection for the different types of cookies we use.',
      /* eslint-enable max-len */
      bodyButtons: 'Select which information services are activated',
      bodyButtonsDeselect: 'Deselect all',
      bodyButtonsSelect: 'Select all',
      bodySeeMore: 'See more',
      bodySeeLess: 'See less',
    },
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Checkout',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Details',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Glossary',
    },
    identification: {
      title: 'Identification',
    },
    knowledge: {
      title: 'Knowledge base',
    },
    landing: {
      description: 'The description of the Landing Page',
    },
    login: {
      title: 'Log in',
      description: 'Log in to your account to invest in our properties.',
    },
    properties: {
      title: 'Funds',
      description: 'Browse our properties and find out more about them here.',
    },
    property: {
      description: 'Invest in {name} now! This property is located at {address}. You can start investing from {min} euros.',
    },
    register: {
      title: 'Register',
      description: 'Sign up for {name} and discover our properties. Register with your e-mail to start investing.',
    },
    reset: {
      title: 'Reset password',
    },
    settings: {
      title: 'Settings',
    },
    verification: {
      title: 'Registration',
    },
  },
};

export default {
  en: mergeWithBrandLangFile(original, 'en'),
};
